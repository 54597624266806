import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axios";

export const getAllNewsAPI = createAsyncThunk(
  "user/getAllNewsAPI",
  async (data, getState) => {
    try {
      const response = await axios.get(
        `/user/news/${data.perPage}/${data.pageOffset}`
      );
      return response.data;
    } catch (e) {
      return getState.rejectWithValue(e.response.data);
    }
  }
);

export const getNewsDetailsAPI = createAsyncThunk(
  "user/getNewsDetailsAPI",
  async (id, getState) => {
    try {
      const response = await axios.get(`/user/newsdetails/${id}`);
      return response.data;
    } catch (e) {
      return getState.rejectWithValue(e.response.data);
    }
  }
);

export const connectWithNewsAPI = createAsyncThunk(
  "user/connectWithNewsAPI",
  async (data, getState) => {
    try {
      const response = await axios.post("/user/connect", data);
      return response.data;
    } catch (e) {
      return getState.rejectWithValue(e.response.data);
    }
  }
);

export const getLetestNewsAPI = createAsyncThunk(
  "user/getLetestNewsAPI",
  async (data, getState) => {
    try {
      const response = await axios.get("/user/letestnews");
      return response.data;
    } catch (e) {
      return getState.rejectWithValue(e.response.data);
    }
  }
);

export const postCommentAPI = createAsyncThunk(
  "user/postCommentAPI",
  async (data, getState) => {
    try {
      const response = await axios.post("/user/comment", data);
      return response.data;
    } catch (e) {
      return getState.rejectWithValue(e.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    newsData: [],
    error: "",
    totalRecord: 0,
    newsDetail: {},
    letestNews: [],
    isPost: false,
  },
  reducers: {
    clearError: (state, action) => {
      state.error = "";
      state.isPost = false;
    },
  },
  extraReducers: {
    [getAllNewsAPI.fulfilled]: (state, action) => {
      state.newsData = action.payload.data;
      state.totalRecord = action.payload.totalRecord;
      state.newPageCount = action.payload.newPageCount;
      state.error = "";
      state.loading = false;
    },
    [getAllNewsAPI.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllNewsAPI.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getNewsDetailsAPI.fulfilled]: (state, action) => {
      state.newsDetail = action.payload;
      state.error = "";
      state.loading = false;
    },
    [getNewsDetailsAPI.pending]: (state, action) => {
      state.loading = true;
    },
    [getNewsDetailsAPI.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [connectWithNewsAPI.fulfilled]: (state, action) => {
      state.isPost = true;
      state.error = "";
      state.loading = false;
    },
    [connectWithNewsAPI.pending]: (state, action) => {
      state.loading = true;
      state.error = "";
      state.isPost = false;
    },
    [connectWithNewsAPI.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getLetestNewsAPI.fulfilled]: (state, action) => {
      state.letestNews = action.payload;
      state.error = "";
      state.loading = false;
    },
    [getLetestNewsAPI.pending]: (state, action) => {
      state.loading = true;
      state.error = "";
    },
    [getLetestNewsAPI.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [postCommentAPI.fulfilled]: (state, action) => {
      state.isPost = true;
      state.loading = false;
    },
    [postCommentAPI.pending]: (state, action) => {
      state.loading = true;
      state.isPost = false;
      state.error = "";
    },
    [postCommentAPI.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { clearError } = userSlice.actions;
export default userSlice.reducer;
