import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import useTitle from "../../utils/useTitle";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PersonCard from "../../components/PersonCard/PersonCard";

import aboutusLogo from "../../Images/aboutusLogo.png";
import aboutusPinkShape from "../../Images/aboutus-pink-shape.png";
import aboutusPolygon1 from "../../Images/aboutus-polygon-right-1.png";
import aboutusPolygon2 from "../../Images/aboutus-polygon-right-2.png";
import alenaPMImage from "../../Images/alena-pm.png";
import dimaBE from "../../Images/dima-be.png";
import sashaIOT from "../../Images/sasha-iot.png";
import dinislamFE from "../../Images/dinislam-fe.png";
import nastyaQA from "../../Images/nastya-qa.png";
import vanyaQA from "../../Images/vanya-qa.png";
import dimaDevOps from "../../Images/dima-devops.png";
import sashaUX from "../../Images/sasha-uxui.png";
import katyaBE from "../../Images/katya-be.png";
import ReactGA from "react-ga";
import "./AboutUs.scss";

const AboutUs = () => {
  const location = useLocation();
  const currentLocation = location.pathname.replace("/", "");
  useTitle(currentLocation);

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="aboutus-main-wrap">
      <Header pageTitle="about us" />
      <div className="about-images-wrapper position-relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-5 rounded-circle d-flex justify-content-center align-items-center">
                <img className="img-fluid" src={aboutusLogo} alt="logo" />
                <div>
                  <span className="text-white alter-title">“alter”</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-paragarph-wrap h-100 d-flex align-items-center">
                <div className="for-section">
                  <h5>
                    <span>Who</span> are we?
                  </h5>
                  <h5 className="title-altr">
                    alt-r houses exceptional humans who bring category-creating
                    solutions to market from the likes of Ambient.ai, Apstra,
                    Cisco, and CoreOS.
                  </h5>
                  <p className="paragraph-altr">
                    Scroll down to learn more about the team
                  </p>
                  <div className="button-join">
                    <button>Join Us</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <div className="">
                <div className="about-meetteam-warp ">
                  <div className="for-section">
                    <h5 className="">
                      <span className="meet-text">Meet </span>&nbsp;the Team
                    </h5>
                    <h4 className="carley-name-title">Carly Stoughton</h4>
                    <h4 className="ceo-founder text-white">CEO + Founder</h4>
                    <p className="paragarph">California Bay Area</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 my-0 my-sm-5 my-lg-0">
              <div className="hexagonal-shape">
                <div className="main-wrap">
                  <div className="shap-wrap ceo"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="order-lg-2 col-lg-6 d-flex justify-content-center align-items-center">
              <div className="">
                <div className="about-meetteam-warp ">
                  <div className="for-section">
                    <h4 className="carley-name-title">Constantine Malyshev</h4>
                    <h4 className="ceo-founder text-white">CTO + Founder</h4>
                    <p className="paragarph">California Bay Area</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="order-lg-1 col-lg-6 my-0 my-sm-5 my-lg-0">
              <div className="hexagonal-shape">
                <div className="main-wrap">
                  <div className="shap-wrap cto"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <div className="">
                <div className="about-meetteam-warp ">
                  <div className="for-section">
                    <h4 className="carley-name-title">Aaron Moore</h4>
                    <h4 className="ceo-founder text-white">
                      Strategic Hardware Partner, Revolution Robotics
                    </h4>
                    <p className="paragarph">Oregon</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 my-0 my-sm-5 my-lg-0">
              <div className="hexagonal-shape">
                <div className="main-wrap">
                  <div className="shap-wrap partner"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-12 my-5">
              <h4 className="meet-title-center d-flex justify-content-center align-items-center ">
                Meet Our Team Leads
              </h4>
              <h5 className="meet-subtitle d-flex justify-content-center align-items-center">
                powered by&nbsp;
                <a className="quantori-link" href="https://quantori.com/">
                  Quantori
                </a>
              </h5>
            </div>
            <div className="col-lg-4 col-md-6 col-12 px-4">
              <PersonCard
                name="Alena"
                position="Product Manager"
                aboutusPerson={alenaPMImage}
              />
            </div>
            <div className="col-lg-4  col-md-6 col-12 px-4">
              <PersonCard
                name="Dmitry"
                position="Cloud/Back End"
                aboutusPerson={dimaBE}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-12 px-4 mt-2 mt-sm-5 mt-lg-0">
              <PersonCard
                name="Alexander"
                position="IoT"
                aboutusPerson={sashaIOT}
              />
            </div>
            <div className="col-lg-4  col-md-6 col-12 px-4 mt-2 mt-sm-5 mt-lg-5">
              <PersonCard
                name="Dinislam"
                position="Front End"
                aboutusPerson={dinislamFE}
              />
            </div>
            <div className="col-lg-4  col-md-6 col-12 px-4 mt-2 mt-sm-5 mt-lg-5">
              <PersonCard
                name="Anastasia"
                position="QA"
                aboutusPerson={nastyaQA}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-12 px-4 mt-2 mt-sm-5 mt-lg-5">
              <PersonCard name="Ivan" position="QA" aboutusPerson={vanyaQA} />
            </div>
            <div className="col-lg-4 col-md-6 col-12 px-4 mt-2 mt-sm-5 mt-lg-5">
              <PersonCard
                name="Kate"
                position="Back End"
                aboutusPerson={katyaBE}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-12 px-4 mt-2 mt-sm-5 mt-lg-5">
              <PersonCard
                name="Dmitry"
                position="DevOps"
                aboutusPerson={dimaDevOps}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-12 px-4 mt-2 mt-sm-5 mt-lg-5">
              <PersonCard
                name="Alexander"
                position="UX/UI"
                aboutusPerson={sashaUX}
              />
            </div>
          </div>
        </div>

        <div className="about-leftfirst-pink  position-absolute">
          <img className="img-fluid" src={aboutusPinkShape} />
        </div>
        <div className="about-leftsecond-pink  position-absolute">
          <img className="img-fluid" src={aboutusPolygon1} />
        </div>
        <div className="about-leftthird-pink  position-absolute">
          <img className="img-fluid" src={aboutusPinkShape} />
        </div>
        <div className="about-leftforth-pink  position-absolute">
          <img className="img-fluid" src={aboutusPinkShape} />
        </div>
        <div className="about-leftfifth-pink  position-absolute">
          <img className="img-fluid" src={aboutusPolygon2} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
