import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import HomePage from "./pages/HomePage/HomePage";
import News from "./pages/News/News";
import AboutUs from "./pages/AboutUs/AboutUs";
import Connect from "./pages/Connect/Connect";
import Login from "./pages/Login/Login";
import NewsDetailPage from "./pages/NewsDetailPage/NewsDetailPage";
import NewsManage from "./admin/pages/NewsManage/NewsManage";
import NewsList from "./admin/pages/NewsList/NewsList";
import NotFound from "./pages/NotFound/NotFound";
import ReactGA from "react-ga";
import ProtectedRoute from "./utils/ProtectedRoute";

import store from "./store/store";

import "./App.css";
ReactGA.initialize('UA-247505394-1');
function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/news" element={<News />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/connect" element={<Connect />} />
          <Route path="/login" element={<Login />} />
          <Route path="/news/:id" element={<NewsDetailPage />} />
          <Route
            path="/admin/newsmanage"
            element={
              <ProtectedRoute>
                <NewsManage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/newslist"
            element={
              <ProtectedRoute>
                <NewsList />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
