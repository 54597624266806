import { NavLink } from "react-router-dom";

import logo from "../../Images/footer-logo-with-text.png";
import Envelope from "../../Images/Email.svg";
import Linkedin from "../../Images/Linkdin.svg";

import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="footer-section">
        <div className="container-fluid">
          <div className="row">
            <div className="news-letter-wrapper">
              <div className="news-head">
                <h4>Newsletter</h4>
                <p>
                  Sign-up for our newsletter to get the latest blogs, tech news,
                  business stories, and more straight to your inbox.
                </p>
              </div>
              <div className="email-wrap">
                <input type="email" placeholder="Enter your email" />
                <button className="btn-sign"> Sign up</button>
              </div>
            </div>
          </div>
          <div className="row mt-2 mt-lg-5">
            <div className="col-md-4 col-12 d-none d-lg-block">
              <div className="footer-logo">
                <NavLink className="nav-link" to="/">
                  <div className="leftContainer">
                    <img className="img-fluid" src={logo} />
                    {/* <label className="logoText">alt-r.ai</label> */}
                    <p>Enterprise Identity, reimagined.</p>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="col-md-3 col-4 p-0">
              <div className="link-menu footer-menu">
                <h5>Quick Links</h5>
                <ul>
                  <li>
                    <NavLink className="nav-link" to="/">
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="nav-link" to="/news">
                      News
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="nav-link" to="/about-us">
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="nav-link" to="/connect">
                      Connect
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="nav-link" to="/login">
                      Log In
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-4 p-0">
              <div className="link-menu">
                <h5>Info</h5>
                <p>hello@alt-r.ai</p>
                <p>Palo Alto, California</p>
              </div>
            </div>
            <div className="col-md-2 col-4 p-0">
              <div className="link-menu">
                <h5>Contact</h5>
                <ul className="social">
                  <li>
                    <a
                      className="icon"
                      href="https://mail.google.com"
                      target="_blank"
                    >
                      <img src={Envelope} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com"
                      target="_blank"
                      className="icon"
                    >
                      <img src={Linkedin} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-12  d-block d-lg-none">
              <div className=" footer-altr mt-4">
                <div className="footer-logo">
                  <NavLink className="nav-link" to="/">
                    <div className="leftContainer">
                      <img className="img-fluid" src={logo} />
                      {/* <label className="logoText">alt-r.ai</label> */}
                      <p>Enterprise Identity, reimagined.</p>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="footer-copy">
              <p>© Copyright 2024 alt-r ai Inc. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
