import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE, PATCH, OPTIONS",
  },
});

axiosInstance.interceptors.request.use((config) => {
  if (!config.headers["Authorization"]) {
    const token = getToken();
    if (token) config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!!error.isAxiosError && !error.response) {
      throw new Error("Unable to connect to network");
    }
    if (error.response?.status === 401) {
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

const getToken = () => {
  const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN);
  return token;
};
