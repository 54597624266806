import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import useTitle from "../../utils/useTitle";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Card from "../../components/Card/Card";
import SubscribeCard from "../../components/SubscribeCard/SubscribeCard";
import Pagination from "../../components/Pagination/Pagination";

import subscribeLog from "../../Images/Subscribe-logo.png";
import Featured from "../../Images/Featured1.png";
import fpolygon1 from "../../Images/f-Polygon1.png";
import fpolygon2 from "../../Images/f-Polygon2.png";
import fpolygon3 from "../../Images/f-Polygon3.png";
import ReactGA from "react-ga";
import "./News.scss";

const News = () => {
  const location = useLocation();

  const currentLocation = location.pathname.replace("/", "");
  useTitle(currentLocation);

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="news-main-wrap">
      <Header pageTitle="news" />
      <section className="catchup-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5 className="text-center catch-title">
                Catch up with the latest from alt-r
              </h5>
            </div>
            <div className="col-md-12">
              <div className="row">
                <Pagination perPage={6} />
              </div>
              {/* <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <Card />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <Card />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="subscribe-card">
                    <div className="card-inner">
                      <img
                        alt="subscribelog"
                        className="d-flex m-auto"
                        src={subscribeLog}
                      />
                      <h5 className="text-center text-white sub-text">
                        Subscribe
                      </h5>
                      <p>
                        Lorem Ipsum Dolor Sit Amet, Consectetuer Adipiscing
                        Elit. Aenean Com- modo Ligula Eget Dolor.
                      </p>
                      <p>
                        Lorem Ipsum Dolor Sit Amet, Consectetuer Adipiscing
                        Elit. Aenean Com- modo Ligula Eget Dolor.
                      </p>
                      <input
                        className="email-input w-100 mb-3"
                        type="email"
                        placeholder="Email Address"
                      />
                      <button className="sign-up-btn w-100">Sign Up</button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <Card />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <Card />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="position-relative mainfeatured-wrap">
                    <div className="featured-card ">
                      <h5 className="m-0 pb-3 mb-3">Featured</h5>
                      <div className="featured-info d-flex gap-10">
                        <img className="w-50" alt="fea" src={Featured} />
                        <div className="w-50">
                          <p className="m-0">
                            Pro Tips: Start Your Reimagine Identity With Our
                            Alt-R...
                          </p>
                          <div className="date-wrap">29 Apr 2022</div>
                          <div className="name-wrap">By David Oneal</div>
                        </div>
                      </div>
                      <div className="featured-info d-flex gap-10">
                        <img className="w-50" alt="fea" src={Featured} />
                        <div className="w-50">
                          <p className="m-0">
                            Pro Tips: Start Your Reimagine Identity With Our
                            Alt-R...
                          </p>
                          <div className="date-wrap">29 Apr 2022</div>
                          <div className="name-wrap">By David Oneal</div>
                        </div>
                      </div>
                      <div className="featured-info d-flex gap-10">
                        <img className="w-50" alt="fea" src={Featured} />
                        <div className="w-50">
                          <p className="m-0">
                            Pro Tips: Start Your Reimagine Identity With Our
                            Alt-R...
                          </p>
                          <div className="date-wrap">29 Apr 2022</div>
                          <div className="name-wrap">By David Oneal</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <img
              alt="polygon"
              className="p-0 poly-img poly-1 img-fluid position-absolute"
              src={fpolygon1}
            />
            <img
              alt="polygon"
              className="p-0 poly-img poly-3 img-fluid position-absolute"
              src={fpolygon3}
            />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default News;
