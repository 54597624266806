import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";

import { getAllNewsAPI } from "../../store/userSlice";

import Card from "../Card/Card";

import "./Pagination.scss";

const Repositories = ({ repositories, className }) => {
  if (repositories.length === 0) {
    return (
      <div style={{ alignItems: "center", display: "flex" }}>
        <ProgressSpinner />
      </div>
      // <div className="alert alert-warning" role="alert">
      //   No results.
      // </div>
    );
  }
  return (
    <div className="catchup-subwrap">
      <div className="container">
        <div className="row">
          {repositories.map((item) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <Card item={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Pagination = ({ perPage }) => {
  const dispatch = useDispatch();

  const { loading, newsData, totalRecord, newPageCount, error } = useSelector(
    (state) => state.user
  );

  const [repositories, setRepositories] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [apiError, setApiError] = useState(null);

  const getData = () => {
    dispatch(getAllNewsAPI({ perPage, pageOffset }));
  };

  const handlePageChange = (event) => {
    setPageOffset(event.selected);
  };

  useEffect(() => {
    setRepositories(newsData);
    const parPageRecord = totalRecord / 6;
    const parPageRecordRound = Math.round(totalRecord / 6);
    if (parPageRecord > parPageRecordRound) {
      setPageCount(parPageRecordRound + 1);
    } else {
      setPageCount(parPageRecordRound);
    }
  }, [newsData]);

  useEffect(() => {
    if (!loading) {
      getData();
    }
  }, [pageOffset, perPage]);

  return (
    <div>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <Repositories className="listing" repositories={repositories} />
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
        forcePage={pageOffset}
      />
    </div>
  );
};

export default Pagination;
