import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

import NavBar from "../../components/NavBar/NavBar";
import NewsView from "../../components/NewsView/NewsView";
import NewsForm from "../../components/NewsForm/NewsForm";
import useTitle from "../../../utils/useTitle";

import {
  getAllNewsAPI,
  clearState,
  deleteNewsAPI,
} from "../../../store/newsSlice";

import logo from "../../../Images/favicon.png";

import "./DataTable.scss";

const NewsList = () => {
  const dispatch = useDispatch();

  const { loading, newsPost, error } = useSelector((state) => state.news);
  useTitle("admin");

  const toast = useRef(null);

  const [newsApiData, setNewsApiData] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    news_title: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    news_subheader: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    news_description: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "created_by.username": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    createdAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [displayMaximizable, setDisplayMaximizable] = useState(false);
  const [position, setPosition] = useState("center");
  const [newsData, setNewsData] = useState(null);

  const getCustomers = (data) => {
    return [...(data || [])].map((d) => {
      d.date = new Date(d.date);
      return d;
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const accept = () => {
    dispatch(deleteNewsAPI(selectedId));
    toast.current.show({
      severity: "info",
      summary: "Confirmed",
      detail: "You have accepted",
      life: 3000,
    });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <img
          className="img-fluid"
          alt={rowData.image}
          src={rowData.image}
          onError={(e) => (e.target.src = logo)}
          style={{
            verticalAlign: "middle",
            height: "100px",
            objectFit: "contain",
          }}
          width={135}
        />
      </React.Fragment>
    );
  };

  const ActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2">
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-warning"
            aria-label="Pencil"
            onClick={() => {
              setIsEdit(true);
              setNewsData(rowData);
            }}
          />
          <Button
            icon="pi pi-eye"
            className="p-button-rounded p-button-seconday"
            aria-label="Eye"
            onClick={() => {
              setDisplayMaximizable(true);
              setPosition("center");
              setNewsData(rowData);
            }}
          />
          <Button
            onClick={() => {
              setVisible(true);
              setSelectedId(rowData._id);
            }}
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger"
            aria-label="Trash"
          />
        </div>
      </React.Fragment>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return moment(rowData.createdAt).format("ll");
  };

  const newsDescriptionBodyTemplate = (rowData) => {
    return (
      <div dangerouslySetInnerHTML={{ __html: rowData?.news_description }} />
    );
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const indexBodyTemplate = (_, props) => {
    return props?.rowIndex + 1;
  };

  const header = renderHeader();
  const onHide = () => {
    if (displayMaximizable) setDisplayMaximizable(false);
    if (isEdit) setIsEdit(false);
  };

  useEffect(() => {
    if (!loading) {
      dispatch(getAllNewsAPI());
    }
  }, []);

  useEffect(() => {
    setNewsApiData(newsPost);
  }, [newsPost]);

  useEffect(() => {
    if (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 3000,
      });
      dispatch(clearState());
    }
  }, [error]);

  return (
    <div>
      <NavBar />
      <Toast ref={toast} />
      <div>
        {/* for news details */}
        <Dialog
          header="News Detail"
          visible={displayMaximizable}
          maximizable
          modal
          style={{ width: "50vw" }}
          onHide={onHide}
        >
          <p className="m-0">
            <NewsView newsData={newsData} />
          </p>
        </Dialog>
        {/* for news edit */}
        <Dialog
          header="Update News"
          visible={isEdit}
          model
          onHide={onHide}
          style={{
            maxWidth: "62%",
          }}
        >
          <div>
            <NewsForm
              newsData={newsData}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
            />
          </div>
        </Dialog>
        {/* no delete confirmation */}
        <ConfirmDialog
          visible={visible}
          onHide={() => {
            setVisible(false);
          }}
          message="Are you sure you want to proceed?"
          header="Confirmation"
          icon="pi pi-info-circle"
          accept={accept}
          reject={reject}
        />
        <DataTable
          value={newsApiData}
          paginator
          className="p-datatable-customers"
          header={header}
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          dataKey="id"
          rowHover
          selection={selectedNews}
          onSelectionChange={(e) => setSelectedNews(e.value)}
          filters={filters}
          filterDisplay="menu"
          loading={loading}
          responsiveLayout="scroll"
          globalFilterFields={[
            "news_title",
            "news_subheader",
            "news_description",
            "created_by.username",
            "createdAt",
          ]}
          emptyMessage="No News found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          {/* <Column
            selectionMode="multiple"
            selectionAriaLabel="name"
            headerStyle={{ width: "3em" }}
          /> */}
          <Column
            header="Id"
            body={indexBodyTemplate}
            style={{ textAlign: "center" }}
          />
          <Column
            field="news_title"
            header="News Title"
            sortable
            filter
            filterPlaceholder="Search by title"
            style={{
              minWidth: "14rem",
            }}
          />
          <Column
            field="news_subheader"
            header="News SubHeader"
            sortable
            filter
            filterPlaceholder="Search by subheader"
            style={{
              maxWidth: "14rem",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          />
          <Column
            field="news_description"
            header="News Description"
            sortable
            filter
            filterPlaceholder="Search by description"
            body={newsDescriptionBodyTemplate}
            style={{
              minWidth: "14rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              webkitLineClamp: "4",
              webkitBoxOrient: "vertical",
            }}
          />
          <Column
            field="created_by.username"
            header="Created By"
            sortable
            filterField="created_by.username"
            style={{ minWidth: "14rem" }}
            filter
            filterPlaceholder="Search by username"
          />
          <Column
            field="image"
            header="Image"
            style={{ minWidth: "14rem" }}
            body={imageBodyTemplate}
          />
          <Column
            field="createdAt"
            header="Date"
            sortable
            filterField="createdAt"
            dataType="date"
            style={{ minWidth: "8rem" }}
            body={dateBodyTemplate}
            // filter
            // filterElement={dateFilterTemplate}
          />
          <Column
            field=""
            header="Action"
            filterField=""
            dataType="date"
            style={{ minWidth: "8rem" }}
            body={ActionBodyTemplate}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default NewsList;
