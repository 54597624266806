import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Toast } from "primereact/toast";

import useTitle from "../../utils/useTitle";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import { connectWithNewsAPI, clearError } from "../../store/userSlice";
import ReactGA from "react-ga";
import "./Connect.scss";

const Connect = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { error, isPost, loading } = useSelector((state) => state.user);
  const currentLocation = location.pathname.replace("/", "");
  useTitle(currentLocation);

  const toast = useRef(null);

  const [data, setData] = useState({
    fullName: "",
    companyName: "",
    email: "",
    message: "",
  });

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const showMessage = (type, message) => {
    toast.current.show({
      severity: type,
      summary: "Message",
      detail: message,
      life: 3000,
    });
  };

  const handleSubmit = () => {
    if (data.fullName && data.companyName && data.email) {
      if (!isValidEmail(data.email))
        return showMessage("error", "Please enter valid email");
      dispatch(connectWithNewsAPI(data));
    } else {
      showMessage("error", "Please fill the details");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (error) showMessage("error", error);
    if (isPost) {
      showMessage("success", "You are connect with alt-r");
      dispatch(clearError());
      setData({
        fullName: "",
        companyName: "",
        email: "",
        message: "",
      });
    }
  }, [error, isPost]);

  return (
    <div className="connect-main-wrap">
      <Header pageTitle="connect" />
      <div className="container">
        <Toast ref={toast} position="top-right"></Toast>
        <div className="get-touch-form">
          <div className="title-contact mt-5">
            <span className="title d-flex justify-content-center align-items-center">
              Get In Touch
            </span>
            <h5 className="d-flex justify-content-center align-items-center">
              Join the Fortune 100s that have hit the ground running with alt-r
              Multipass.
            </h5>
            <div className="form-page-wrap">
              <div className="form-group-box">
                <label for="exampleInputEmail1" className="form-label">
                  Full Name*
                </label>
                <input
                  name="fullname"
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter your name"
                  value={data.fullName}
                  onChange={(e) => {
                    setData({ ...data, fullName: e.target.value });
                  }}
                  required
                />
              </div>
              <div className="form-group-box">
                <label for="exampleInputPassword1" className="form-label">
                  Company Name*
                </label>
                <input
                  name="companyName"
                  type="text"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter your company name"
                  value={data.companyName}
                  onChange={(e) => {
                    setData({ ...data, companyName: e.target.value });
                  }}
                  required
                />
              </div>
              <div className="form-group-box">
                <label for="exampleInputEmail1" className="form-label">
                  Email Address*
                </label>
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter your email"
                  value={data.email}
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                  }}
                  required
                />
              </div>
              <div className="form-group-box1">
                <label for="TextareaMessage" className="form-label">
                  Message
                </label>
                <textarea
                  name="message"
                  className="form-control"
                  rows="5"
                  placeholder="Write optional message here"
                  id="TextareaMessage"
                  value={data.message}
                  onChange={(e) => {
                    setData({ ...data, message: e.target.value });
                  }}
                ></textarea>
              </div>

              <div className="button-submit d-flex justify-content-center align-items center my-5">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Connect;
