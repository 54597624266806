import React from "react";
import { useNavigate } from "react-router-dom";

import "./NotFound.scss";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/home");
  };

  return (
    <div className="main-wraper">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="error-template">
              <h1>Oops!</h1>
              <h2>404 Not Found</h2>
              <div className="error-details">
                Sorry, an error has occured. Requested page not found!
              </div>
              <div className="error-actions">
                <button
                  className="btn btn-primary btn-lg"
                  onClick={handleGoBack}
                >
                  <span className="glyphicon glyphicon-home"></span>
                  Go to Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
