import React from "react";
import moment from "moment";

import blogImage from "../../../Images/blogdetail.png";

import "./NewsView.scss";

const NewsView = ({ newsData }) => {
  return (
    <div>
      <div className="model-box-images">
        <img
          className="img-fluid"
          src={newsData?.image}
          onError={(e) => (e.target.src = blogImage)}
          alt="image"
        />
      </div>
      <div className="my-2">
        <h5 className="title-box">{newsData?.news_title}</h5>
        <div
          className="pargraph-model"
          dangerouslySetInnerHTML={{ __html: newsData?.news_description }}
        />
      </div>
      <div className="my-2">
        <h5 className=" d-flex justify-content-end title-wrap m-0">
          <span className="title-published"> Published:</span>
          <span className="date">
            {moment(newsData?.created_at).format("MMM Do YYYY")}
          </span>
        </h5>
        <span className="owner-name d-flex justify-content-end ">
          {`By ${newsData?.created_by?.username}`}
        </span>
      </div>
    </div>
  );
};

export default NewsView;
