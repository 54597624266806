import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Toast } from "primereact/toast";
import moment from "moment";

import profileImg from "../../Images/Profileimg.png";
// import blogDetailImg from "../../Images/blogdetail.png";
import blogpolygon1 from "../../Images/blogpolygon1.png";
import blogpolygon2 from "../../Images/blogpolygon2.png";
import blogpolygon3 from "../../Images/blogpolygon3.png";

import { postCommentAPI, clearError } from "../../store/userSlice";

import "./BlogDetails.scss";

const BlogDetails = ({ newsDetail }) => {
  const dispatch = useDispatch();

  const { isPost, error, loading } = useSelector((state) => state.user);
  const [data, setData] = useState({
    fullName: "",
    email: "",
    comment: "",
  });

  const toast = useRef(null);

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const showMessage = (type, message) => {
    toast.current.show({
      severity: type,
      summary: "Message",
      detail: message,
      life: 3000,
    });
  };

  const handleSubmit = (e) => {
    if (data.fullName && data.email && data.comment) {
      if (!isValidEmail(data.email))
        return showMessage("error", "Please enter valid email");
      if (data) dispatch(postCommentAPI(data));
    } else {
      showMessage("error", "Please fill detail");
    }
  };

  useEffect(() => {
    if (newsDetail?._id) setData({ ...data, newsId: newsDetail?._id });
  }, [newsDetail]);

  useEffect(() => {
    if (error) showMessage("error", error);
    if (isPost) {
      showMessage("success", "Comment posted");
      dispatch(clearError());
      setData({ ...data, fullName: "", email: "", comment: "" });
    }
  }, [error, isPost]);

  return (
    <div className="content-wrapper ">
      <Toast ref={toast} position="top-right"></Toast>
      <div className="container">
        <div className="img-wrap ">
          <img alt="newsimage" src={newsDetail?.image} />
        </div>
        <div className="catch-box">
          <div className="catch-border">
            <div className="catch-title mb-4">
              {newsDetail?.news_title}
              {/* Catch up with the latest from alt-r */}
            </div>
            <div className="catch-subtitle d-flex   gap-2">
              <h4 className="catch-details ">
                {newsDetail?.news_subheader
                  ? newsDetail?.news_subheader
                  : "Lorem Ipsum is simply dummy text of the printing and typesetting industry"}
              </h4>
              <div>
                <div className="d-flex comment-wrap ">
                  <div className="d-flex align-items-center  profile-wrap">
                    <div className="rounded profile-img">
                      <img alt="profile" src={profileImg} />
                    </div>
                    <div className="owner-name text-white ms-2 ms-lg-2">
                      {`By ${newsDetail?.created_by?.username}`}
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="date-wrap">
                      Published:{" "}
                      <span>
                        {moment(newsDetail?.created_at).format("DD MMM YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="text__discription mb-4 mt-5"
              dangerouslySetInnerHTML={{ __html: newsDetail?.news_description }}
            />
          </div>
          {/* <div className="d-flex justify-content-between comment-wrap">
            <div className="d-flex align-items-center profile-wrap">
              <div className="rounded profile-img">
                <img src={profileImg} />
              </div>
              <div className="owner-name text-white ms-2 ms-lg-3">
                By David Oneal
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="date-wrap">
                Plublished: <span> 29 Apr 2022</span>
              </div>
            </div>
          </div> */}
          <div className="catch-title mb-4 leave-title">Leave a comment</div>
          <div className="row">
            <div className="col-md-6">
              <div className="input-wrap">
                <span>Full Name*</span>
                <input
                  className="w-100 mt-3"
                  type="text"
                  id="fname"
                  name="fullname"
                  placeholder="Jhoana May Ignacio"
                  value={data.fullName}
                  onChange={(e) => {
                    setData({ ...data, fullName: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-wrap">
                <span>Email Address*</span>
                <input
                  className="w-100 mt-3"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter Your Email Address"
                  value={data.email}
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="input-wrap">
                <span> Comment*</span>
                <textarea
                  className="w-100 mt-3"
                  id="w3review"
                  name="comment"
                  rows="4"
                  cols="0"
                  placeholder=" Type Your Comment....."
                  value={data.comment}
                  onChange={(e) => {
                    setData({ ...data, comment: e.target.value });
                  }}
                ></textarea>
              </div>
              <button
                className="d-flex m-auto submit-btn align-items-center justify-content-center"
                onClick={handleSubmit}
              >
                SUBMIT Comment
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="blog-polygon-pink ">
          <img alt="polygon" className="img-fluid" src={blogpolygon2} />
        </div>
        <div className="blog-polygon-orange">
          <img alt="polygon" className="img-fluid" src={blogpolygon1} />
        </div>
        <div className="blog-polygon-multicolor">
          <img alt="polygon" className="img-fluid" src={blogpolygon3} />
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
