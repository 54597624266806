import { NavLink, useLocation, useNavigate } from "react-router-dom";

import logo from "../../Images/logo.svg";

import "./NavBar.scss";

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentLoction = location.pathname;

  const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/home");
  };

  return (
    <div className="menu-wrapper">
      <nav className="navbar navContainer navbar-expand-lg">
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/home">
            <div className="leftContainer">
              <img className="logo" src={logo} />
              <label className="logo logoText">alt-r.ai</label>
            </div>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="menu-icon-bar">
              <i className="fa-solid fa-bars"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className={
                    currentLoction === "/" ? "nav-link active" : "nav-link"
                  }
                  aria-current="page"
                  to="/home"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currentLoction === "/news" ? "nav-link active" : "nav-link"
                  }
                  to="/news"
                >
                  News
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currentLoction === "/about-us"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/about-us"
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currentLoction === "/connect"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/connect"
                >
                  Connect
                </NavLink>
              </li>
              <li className="nav-item">
                {token ? (
                  <buton
                    type="button"
                    className="btn loginButton"
                    onClick={handleLogout}
                  >
                    Logout
                  </buton>
                ) : (
                  <NavLink
                    type="button"
                    className="btn loginButton"
                    to="/login"
                  >
                    Login
                  </NavLink>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
