import { NavLink } from "react-router-dom";
import moment from "moment";

import blog1 from "../../Images/img2.png";

import "./Card.scss";

const Card = ({ item }) => {
  return (
    <div className="card-blog">
      <div className="blog-btn-wrapper">
        <div className="blog-img">
          <img src={item?.image} className="img-fluid" />
        </div>
      </div>
      <h5 className="text-teunc">
        {item?.news_title}
        {/* Pro Tips: Start Your Reimagine Identity With Our Alt-R... */}
      </h5>
      <div className="text-sub"><p>{item?.news_subheader}</p></div>
      <div
        className="text-description"
        dangerouslySetInnerHTML={{ __html: item?.news_description }}
      />
      {/* {item?.news_description} */}
      {/* Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. */}
      {/* </p> */}
      <div className="date">
        <p>{`By ${item?.created_by?.username}`}</p>
        <span>{moment(item?.created_at).format("DD MMM YYYY")}</span>
      </div>
      <div className="btn-wrap">
        <NavLink className="btn-learn" to={`/news/${item?._id}`}>
          Learn More
        </NavLink>
      </div>
    </div>
  );
};

export default Card;
