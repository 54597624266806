import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import logo from "../../../Images/logo.svg";

import { clearState } from "../../../store/authSlice";

import "./NavBar.scss";

const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(clearState());
    localStorage.clear();
    navigate("/home");
  };

  return (
    <div className="main-wrapper-navbar">
      <nav className="navbar navbar-expand-lg navbar-dark ">
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/home">
            <div className="leftContainer">
              <img className="logo" src={logo} />
              <label className="logoText">alt-r.ai</label>
            </div>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  aria-current="page"
                  to="/admin/newslist"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/admin/newsmanage">
                  Add News
                </NavLink>
              </li>

              <li className="nav-item">
                <div className="logout-button d-flex" role="search">
                  <button className="btn btn-light" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
