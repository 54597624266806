import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axios";

export const postNewsAPI = createAsyncThunk(
  "news/postNewsAPI",
  async (data, getState) => {
    try {
      const response = await axios.post("/news/", data);
      return response.data.data;
    } catch (e) {
      return getState.rejectWithValue(e.response.data);
    }
  }
);

export const getAllNewsAPI = createAsyncThunk(
  "news/getAllNewsAPI",
  async (data, getState) => {
    try {
      const response = await axios.get("/news/");
      return response.data;
    } catch (e) {
      return getState.rejectWithValue(e.response.data);
    }
  }
);

export const deleteNewsAPI = createAsyncThunk(
  "news/deleteNewsAPI",
  async (id, getState) => {
    try {
      const response = await axios.delete(`/news/${id}`);
      return response.data;
    } catch (e) {
      return getState.rejectWithValue(e.response.data);
    }
  }
);

export const updateNewsAPI = createAsyncThunk(
  "news/updateNewsAPI",
  async (data, getState) => {
    try {
      const response = await axios.put("/news/", data);
      return response.data;
    } catch (e) {
      return getState.rejectWithValue(e.response.data);
    }
  }
);

const newsSlice = createSlice({
  name: "news",
  initialState: {
    loading: false,
    newsPost: [],
    isPost: false,
    error: "",
  },
  reducers: {
    clearState: (state, action) => {
      state.error = "";
      state.isPost = false;
      state.loading = false;
    },
  },
  extraReducers: {
    [postNewsAPI.fulfilled]: (state, action) => {
      state.newsPost = action.payload;
      state.loading = false;
      state.isPost = true;
    },
    [postNewsAPI.pending]: (state, action) => {
      state.loading = true;
      state.isPost = false;
      state.error = "";
    },
    [postNewsAPI.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getAllNewsAPI.fulfilled]: (state, action) => {
      state.newsPost = action.payload;
      state.loading = false;
    },
    [getAllNewsAPI.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllNewsAPI.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteNewsAPI.fulfilled]: (state, action) => {
      state.newsPost = action.payload;
      state.loading = false;
    },
    [deleteNewsAPI.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteNewsAPI.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateNewsAPI.fulfilled]: (state, action) => {
      state.newsPost = action.payload;
      state.loading = false;
      state.isPost = true;
    },
    [updateNewsAPI.pending]: (state, action) => {
      state.loading = true;
      state.error = "";
      state.isPost = false;
    },
    [updateNewsAPI.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { clearState } = newsSlice.actions;
export default newsSlice.reducer;
