import NavBar from "../NavBar/NavBar";

import "./Header.scss";

const Header = ({ pageTitle }) => {
  return (
    <section className="menu">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-md-12">
            <NavBar />
            <div className="d-flex align-items-center  justify-content-center title">
              <h3 className="text-center text-white text-capitalize">
                {pageTitle}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
