import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import newsSlice from "./newsSlice";
import userSlice from "./userSlice";
import { combineReducers } from "redux";

const store = configureStore({
  reducer: combineReducers({
    auth: authSlice,
    news: newsSlice,
    user: userSlice,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { ignoredPaths: ["some.nested.path"] },
      serializableCheck: { ignoredPaths: ["some.nested.path"] },
    }),
});

export default store;
