import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import NewsForm from "../../components/NewsForm/NewsForm";
import useTitle from "../../../utils/useTitle";

import "./NewsManage.scss";

const NewsManage = () => {
  useTitle("admin");

  return (
    <div>
      <NavBar />
      <div className="news-main-wrapper">
        <div className="form-container">
          <NewsForm />
        </div>
      </div>
    </div>
  );
};

export default NewsManage;
