import React from "react";

import aboutusPolygon from "../../Images/aboutus-polygon.png";
// import aboutusPerson from "../../Images/aboutus-person.png";

import "./PersonCard.scss";

const PersonCard = ({ name, position, aboutusPerson }) => {
  return (
    <div className="card-wrapper-box">
      <div className="person-images">
        <img className="img-fluid " src={aboutusPerson} />
      </div>
      <div className="button-box">
        <div className="board">
          <span className="person-name">{name}</span>
          <span className="post-name">{position}</span>
        </div>
      </div>
    </div>
  );
};

export default PersonCard;
