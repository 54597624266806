import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import useTitle from "../../utils/useTitle";

import identity from "../../Images/for-login-Identity.png";
import logo from "../../Images/logo-with-text.png";
import emailFill from "../../Images/email-Fill.svg";
import loginBg from "../../Images/login-bottom.png";
import passwordFill from "../../Images/Password Pink.svg";
import passwordUnfill from "../../Images/PasswordUnfill.svg";
import ReactGA from "react-ga";
import { loginAPI } from "../../store/authSlice";

import "./Login.scss";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useRef(null);

  const { userData, error, isLogin } = useSelector((state) => state.auth);

  const currentLocation = location.pathname.replace("/", "");
  useTitle(currentLocation);

  const [data, setData] = useState({ email: "", password: "" });
  const [isShowPassword, setIsShowPassword] = useState(false);
  const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN);

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const setUpdateValue = (e) => {
    const updateValue = data;
    updateValue[e.target.name] = e.target.value;
    setData(updateValue);
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const showMessage = (type, message) => {
    toast.current.show({
      severity: type,
      summary: "Message",
      detail: message,
      // life: 3000,
    });
  };

  const handleSubmit = () => {
    if (data.email && data.password) {
      if (!isValidEmail(data.email))
        return showMessage("error", "Please enter valid email");
      if (data) dispatch(loginAPI(data));
    } else {
      showMessage("error", "Please fill detail");
    }
  };

  useEffect(() => {
    if (error) showMessage("error", error);
    ReactGA.pageview(window.location.pathname);
  }, [error]);

  useEffect(() => {
    if (isLogin) {
      showMessage("success", "Login success");
      setTimeout(() => {
        if (userData?.isAdmin) navigate("/admin/newslist");
        if (!userData?.isAdmin) navigate("/home");
      }, 1000);
    }
  }, [isLogin]);

  return (
    <div className="main-wrapper-box">
      <Toast ref={toast} position="top-right"></Toast>
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-lg-6 p-0 d-none  d-lg-block login-box-imges">
            <div className="login-background-wrapper   ">
              <div className="login-wrapper">
                <div className="altr-logo">
                  <img src={logo} className="img-fluid" />
                </div>
                <div className="identity-card">
                  <img src={identity} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-0 ">
            <div className="alt-login-wrapper">
              <div className="custom-login-form">
                <div className=" d-flex justify-content-center align-items-center flex-column">
                  <h4 className="login-title ">Login</h4>
                  <p>Please login to access your account!</p>
                </div>
                <div className="form-login-pages">
                  <div className="form-group-box ">
                    <label for="exampleInputEmail1" className="form-label">
                      Email
                    </label>
                    <div className="position-relative">
                      <span className="email-icon position-absolute">
                        <img src={emailFill} className="img-fluid" />
                      </span>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter your email"
                        onChange={setUpdateValue}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <div className="form-group-box">
                      <label for="exampleInputEmail1" className="form-label">
                        Password
                      </label>
                      <div className="position-relative">
                        <span
                          className="email-icon position-absolute"
                          onClick={handleShowPassword}
                        >
                          <img
                            src={isShowPassword ? passwordUnfill : passwordFill}
                            className="img-fluid"
                          />
                        </span>
                        <input
                          name="password"
                          type={isShowPassword ? "text" : "password"}
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter your password"
                          onChange={setUpdateValue}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="check-box-title  d-flex justify-content-between align-items-center ">
                    <div className="d-flex gap-2">
                      <div className="check-box">
                        <input className="form-check-input" type="checkbox" />
                      </div>
                      <span className="remeber-title">Remember me</span>
                    </div>
                    <h5 className="forget">
                      <a className="">Forgot Password?</a>
                    </h5>
                  </div>
                  <div className="mt-2">
                    <div className="button-wrapper">
                      <button className="button" onClick={handleSubmit}>
                        Log In
                      </button>
                    </div>
                    <span className="title my-2  d-flex justify-content-center align-items-center">
                      OR
                    </span>
                    <div className="button-gray">
                      <button>Continue with SSO Provider</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
