import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import moment from "moment";

import useTitle from "../../utils/useTitle";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";

import businessman from "../../Images/business-man.svg";
import headerCurve from "../../Images/curve.svg";
import hexagonal from "../../Images/hexa.svg";
import Quote from "../../Images/quote.svg";
import mobileHexa from "../../Images/Polygon10.svg";
import iconImg from "../../Images/Icard.png";
import userId from "../../Images/identity.png";
import blog1 from "../../Images/img2.png";
import mobileDeviceBanner from "../../Images/mobilebanner.png";
import idCardGIF from "../../Images/homepage-gif.gif";

import { getLetestNewsAPI } from "../../store/userSlice";
import ReactGA from "react-ga";

import "./HomePage.scss";

const HomePage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentLocation = location.pathname.replace("/", "");
  useTitle(currentLocation);

  const { error, letestNews } = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    dispatch(getLetestNewsAPI());
  }, []);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="main-wrapper">
      <section className="header-section">
        <div className="banner">
          <div className="curve">
            <img src={headerCurve} className="img-fluid" alt="headerCurve" />
          </div>
          <div className="businessmanImage">
            <img src={businessman} className="img-fluid" alt="officeimage" />
          </div>
          <div className="penta">
            <img src={mobileHexa} className="img-fluid" alt="mobilehexa" />
          </div>
        </div>
        <div className="container ">
          <div className="row">
            <NavBar />
            <div className="col-md-12 col-lg-8 col-xs-12">
              <div className="bannerContent">
                <p className="headerMainText">
                  Dynamic Identity for People, Places, and Things
                </p>
                <p className="headerText">
                alt-r provides a more secure solution for your most pervasive endpoint: the badge. <br/>Static badges are out - protect your campus with dynamic&nbsp;,&nbsp;
                  <NavLink to="">cloud-managed</NavLink> badging and access
                  solutions.
                </p>
                <NavLink className="btn connectButton" to="/connect">
                  Connect
                </NavLink>
              </div>
              <div className="mobile-banner d-block d-lg-none">
                <img
                  src={mobileDeviceBanner}
                  className="img-fluid"
                  alt="mobilebanner"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="security-wrapper">
        <div className="securityHead">
          <h3> Why should you reconsider static security?</h3>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xs-12 mt-0 mt-lg-3 px-4">
              <div className="service-wrapper">
                <div className="round">
                  <img
                    src={iconImg}
                    className="img-fluid"
                    style={{ marginBottom: "23px" }}
                    alt="icon"
                  />
                </div>
                <div className="hexa">
                  <img src={hexagonal} className="img-fluid" alt="shape" />
                  <div className="serviceText">
                    <p>EVERYONE HAS A BADGE TO signal belonging. </p>
                    <p>but how secure is a static piece of plastic?</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12 mt-0 mt-lg-3 px-4">
              <div className="service-wrapper">
                <div className="round">
                  <h2>10%</h2>
                </div>
                <div className="hexa">
                  <img src={hexagonal} className="img-fluid" alt="shape" />
                  <div className="serviceText">
                    <p>
                      OF DATA BREACHES ARE ATTRIBUTED TO A PHYSICAL SECURITY
                      COMPROMISE*, e.g. tailgating
                    </p>
                    <p>BADGES SHOULD be updated and validated</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12 mt-0 mt-lg-3 px-4">
              <div className="service-wrapper">
                <div className="round">
                  <h2>$3.54 M</h2>
                </div>
                <div className="hexa">
                  <img src={hexagonal} className="img-fluid" alt="shape" />
                  <div className="serviceText">
                    <p>
                      AVERAGE COST OF A PHYSICAL SECURITY DATA BREACH* (AND
                      CLIMBING!)
                    </p>
                    <div className="link-hexagonal d-flex justify-content-center align-items-center gap-2">
                      <span className="">* </span>
                      <a
                        href="https://www.ibm.com/security/data-breach"
                        target="_blank"
                      >
                        https://www.ibm.com/security/data-breach
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="manage-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="position-relative images-box">
                <img src={userId} className="img-fluid" alt="shape" />
                <div className="gifWapper">
                  <img src={idCardGIF} className="gifImage" alt="gif" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="manage-content">
                <h4>
                  Cloud-managed secure badges that evolve with the employee
                  lifecycle
                </h4>
                <div className="for-section">
                  <h5>
                    <span>For</span> security teams
                  </h5>
                  <p>
                    Badges should be dynamic and update over-the-air to add
                    secure elements (e.g. a rotating daily icon to ensure the
                    badge is valid on a daily basis), or rotating QR codes to
                    provide important information.
                  </p>
                </div>
                <div className="for-section">
                  <h5>
                    <span>For</span> employees
                  </h5>
                  <p>
                    Badges should evolve with employees’ tenure. Employees may
                    change departments, locales, status, need to update recent
                    ID pictures, and express their corporate group membership -
                    like becoming a member of the emergency response team.
                    Badges shouldn’t have to be reissued to reflect this.
                  </p>
                </div>
                <div className="for-section">
                  <h5>
                    <span>For</span> visitor management
                  </h5>
                  <p>
                    Not only do current visitor badges waste valuable resources,
                    they do not ensure the visitor stays in approved areas, nor
                    give them any dynamic information, like maps. Multipass
                    badges have the capacity to do both - reusable badges that
                    provide valuable dynamic information.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial">
        <div className="head-text">
          <h3>Curious? Here’s what our customers and prospects have to say.</h3>
        </div>

        <div className="testimonial-slide">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div className="col-md-3">
                  <div className="box">
                    <div className="quote">
                      <img src={Quote} alt="quote" />
                    </div>
                    <div className="carousel-text">
                      {/* <h5> Dynamic identity and security is long overdue.</h5> */}
                      <h3>This is physical security nirvana!</h3>
                    </div>
                    <div className="link">
                      <a>Director, Physical Security + Resiliency</a>
                    </div>
                    <div className="link">
                      <a>Future 50</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="box">
                    <div className="quote">
                      <img src={Quote} alt="quote" />
                    </div>
                    <div className="carousel-text">
                      <h5> Dynamic identity and security is long overdue.</h5>
                      <h6 className="need-title">I need this.</h6>
                    </div>
                    <div className="link">
                      <a>Security Program Manager</a>
                    </div>
                    <div className="link">
                      <a>Fortune 100</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="box">
                    <div className="quote">
                      <img src={Quote} alt="quote" />
                    </div>
                    <div className="carousel-text">
                      <h5>
                        Identity is incredibly, incredibly, INCREDIBLY flawed in
                        the US. What you’re doing is extremely innovative in
                        that regard.
                      </h5>
                      {/* <h3>I need this.</h3> */}
                    </div>
                    <div className="link">
                      <a>Manager, Security Strategy + Real Estate</a>
                    </div>
                    <div className="link">
                      <a>Fortune 1000</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="box">
                    <div className="quote">
                      <img src={Quote} alt="quote" />
                    </div>
                    <div className="carousel-text">
                      <h5>
                        alt-r thought this out the right way out of the gate. We
                        want to go right from POC to Production.
                      </h5>
                      {/* <h3>I need this.</h3> */}
                    </div>
                    <div className="link">
                      <a>Program Manager, Global Security</a>
                    </div>
                    <div className="link">
                      <a>Fortune 500</a>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <div className="box">
                    <div className="quote">
                      <img src={Quote} />
                    </div>
                    <div className="carousel-text">
                      <h5> Dynamic identity and security is long overdue.</h5>
                      <h3>I need this.</h3>
                    </div>
                    <div className="link">
                      <a>Director, Physical Security + Resiliency</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="box">
                    <div className="quote">
                      <img src={Quote} />
                    </div>
                    <div className="carousel-text">
                      <h5> Dynamic identity and security is long overdue.</h5>
                      <h3>I need this.</h3>
                    </div>
                    <div className="link">
                      <a>Director, Physical Security + Resiliency</a>
                    </div>
                  </div>
                </div> */}
              </Slider>
            </div>
            <div className="row">
              <div className="btnGroup">
                <NavLink className="btn-connect" to="/connect">
                  Connect
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog-section">
        <div className="container">
          <div className="row">
            {letestNews?.length ? (
              <div className="col-md-5">
                <div className="card-blog">
                  <div className="blog-btn-wrapper">
                    <img
                      src={letestNews[0]?.image}
                      className="img-fluid"
                      alt="blog"
                    />
                  </div>
                  <h5 className="text-teunc">{letestNews[0]?.news_title}</h5>
                  <div className="text-sub"><p>{letestNews[0]?.news_subheader}</p></div>
                  <div
                    className="text-pharagraph"
                    dangerouslySetInnerHTML={{
                      __html: letestNews[0]?.news_description,
                    }}
                  />
                  {/* {letestNews[0]?.news_description}</p> */}
                  <div className="date">
                    <p>{`By ${letestNews[0]?.created_by?.username}`}</p>
                    <span>
                      {/* {moment(letestNews[0]?.created_at).format("DD MMM YYYY")} */}
                      29 Apr 2022
                    </span>
                  </div>
                  <div className="btn-wrap">
                    <NavLink
                      className="btn-learn"
                      to={`/news/${letestNews[0]?._id}`}
                    >
                      Learn More
                    </NavLink>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-5">
                <div className="card-blog">
                  <div className="blog-btn-wrapper">
                    <img src={blog1} className="img-fluid" alt="blog" />
                    {/* <button>Reimagine</button> */}
                  </div>
                  <h6 className="text-teunc">
                    Pro Tips: Start Your Reimagine Identity With Our Alt-R...
                  </h6>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                  <div className="date">
                    <p>By David Oneal</p>
                    <span>29 Apr 2022</span>
                  </div>
                  <div className="btn-wrap">
                    <NavLink className="btn-learn" to="/news/1">
                      Learn More
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-7">
              <div className="latest-wrap">
                <h5>Check out the latest news from alt-r.ai</h5>
                <div className="btnGroup">
                  <NavLink className="btn-post" to="/news">
                    View All News
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default HomePage;
