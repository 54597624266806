import { useEffect } from "react";

const useTitle = (title) => {
  useEffect(() => {
    const prevTitle = `alt-r ${document.title}`;
    document.title = `alt-r ${title}`;
    return () => {
      document.title = prevTitle;
    };
  });
};

export default useTitle;
