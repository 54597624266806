import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useTitle from "../../utils/useTitle";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import BlogDetails from "../../components/BlogDetails/BlogDetails";

import { getNewsDetailsAPI, clearError } from "../../store/userSlice";

import leftArrow from "../../Images/leftarrow.png";
import ReactGA from "react-ga";
import "./NewsDetailPage.scss";

const NewsDetailPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { error, newsDetail } = useSelector((state) => state.user);

  const id = location?.pathname?.split("/news/")[1];
  useTitle("newsdetail");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (id) dispatch(getNewsDetailsAPI(id));
    ReactGA.pageview(window.location.pathname);
  }, [id]);

  return (
    <div className="blogdetail-wrap">
      <div className="news-main-wrap">
        <section className="news-menu">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <NavBar />
                <div className="d-flex align-items-center  justify-content-start news-title">
                  <div className="aero-button d-flex align-items-center  justify-content-start">
                    <button className="left-aero-button">
                      <NavLink to="/news">
                        <img alt="backarrow" src={leftArrow} />
                      </NavLink>
                    </button>
                    <span className="title-back-blog">
                      <NavLink to="/news">Back to News</NavLink>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="blog-images ">
        {error ? (
          <p style={{ color: "red", textAlign: "center" }}>{error}</p>
        ) : (
          <BlogDetails newsDetail={newsDetail} />
        )}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default NewsDetailPage;
