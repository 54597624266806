import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactQuill from "react-quill";
import { Toast } from "primereact/toast";

import {
  postNewsAPI,
  updateNewsAPI,
  clearState,
} from "../../../store/newsSlice";

import "./NewsForm.scss";
import "react-quill/dist/quill.snow.css";

const NewsForm = ({ newsData, isEdit, setIsEdit }) => {
  const dispatch = useDispatch();

  const formData = new FormData();

  const imageName = useRef();
  const toast = useRef(null);

  const { isPost, error } = useSelector((state) => state.news);
  const [richText, setRichText] = useState("");

  const [data, setData] = useState({
    news_title: "",
    news_subheader: "",
    news_description: "",
    image: "",
  });

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const changeImage = (e) => {
    const file = e.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      showMessage("error", "Select valid image");
      return false;
    }
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setData({ ...data, image: result });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showMessage = (type, message) => {
    toast.current.show({
      severity: type,
      summary: "Message",
      detail: message,
      life: 3000,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data?.news_title && data?.news_subheader && richText && data?.image) {
      formData.append("news_title", data.news_title);
      formData.append("news_subheader", data.news_subheader);
      formData.append("news_description", richText);
      formData.append("image", data.image);
      formData.append("id", newsData?._id);
      if (isEdit) {
        dispatch(updateNewsAPI(formData));
      } else {
        dispatch(postNewsAPI(formData));
      }
    } else {
      showMessage("error", "Please fill the details");
    }
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  useEffect(() => {
    if (error) showMessage("error", error);
    if (isPost) {
      if (isEdit) {
        showMessage("success", "News Updated");
        setTimeout(() => {
          setIsEdit(false);
        }, 1000);
      } else {
        showMessage("success", "News Posted");
      }
      setData({
        news_title: "",
        news_subheader: "",
        news_description: "",
        image: "",
      });
      setRichText("");
      imageName.current.value = null;
      dispatch(clearState());
    }
  }, [error, isPost]);

  useEffect(() => {
    if (isEdit) {
      setData({
        news_title: newsData?.news_title,
        news_description: newsData?.news_description,
        news_subheader: newsData?.news_subheader,
        image: newsData?.image,
      });
      setRichText(newsData?.news_description);
    }
  }, [isEdit]);

  return (
    <div className="form-name-wrapper">
      <Toast ref={toast} position="top-right"></Toast>
      <form className={isEdit ? "" : "form-box"}>
        <div className="form-pages-box mb-3">
          <label for="newstitle" className="form-label">
            News Title
          </label>
          <input
            name="news_title"
            type="text"
            className="form-control"
            id="newstitle"
            placeholder="Enter News Title"
            value={data.news_title}
            onChange={(e) => {
              setData({ ...data, news_title: e.target.value });
            }}
          />
        </div>
        <div className="form-pages-box mb-3">
          <label for="newsubheader" className="form-label">
            News SubHeader
          </label>
          <input
            name="news_subheader"
            type="text"
            className="form-control"
            id="newssubheader"
            placeholder="Enter News Subheader"
            value={data.news_subheader}
            onChange={(e) => {
              setData({ ...data, news_subheader: e.target.value });
            }}
          />
        </div>
        <div className="mb-3 form-pages-box1">
          <label for="newsdetails" className="form-label">
            News Details
          </label>
          <ReactQuill
            theme="snow"
            onChange={setRichText}
            value={richText}
            modules={modules}
            formats={formats}
            bounds={".app"}
            placeholder="News Description"
          />
          {/* <textarea
            name="news_description"
            className="form-control"
            id="newsdetails"
            rows="7"
            placeholder="Enter News Description"
            value={data.news_description}
            onChange={(e) => {
              setData({ ...data, news_description: e.target.value });
            }}
          ></textarea> */}
        </div>
        <div className="mb-3 form-pages-box2">
          <label for="newsimage" className="form-label">
            News Image
          </label>
          <input
            name="image"
            ref={imageName}
            className="form-control"
            type="file"
            id="newimage"
            value={data.imageName}
            onChange={changeImage}
          />
        </div>
        {isEdit && (
          <div className="mb-3">
            <img
              className="img-fluid"
              style={{ width: "450px" }}
              src={newsData?.image}
            />
          </div>
        )}
        <div className="button-save">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            {isEdit ? "Update" : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewsForm;
